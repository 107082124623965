/** ***************************************************************************
 * Ecommerce / Templates / Homepage
 *************************************************************************** */

window.addEventListener('conchiglia:init', () => {

  function setupEventListeners() {}

  function initialize() {
    setupEventListeners();
  }

  function destroy() {}

  window.CONCHIGLIA.register(
    'ecommerce-templates-homepage',
    initialize,
    destroy
  );

  initialize();
});
